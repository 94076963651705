/**
 * useOutsideClick.tsx
 *
 * This custom Hook invokes a callback when a click event is detected outside the reference element.
 * Source: https://medium.com/@kevinfelisilda/click-outside-element-event-using-react-hooks-2c540814b661
 */

import { RefObject, useEffect } from "react";

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void
): void => {
  const handleClick = (e: MouseEvent) => {
    if (ref?.current && !ref.current.contains(e.target as any)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const useOutsideClickMultiple = (
  refs: RefObject<HTMLElement>[],
  callback: () => void
): void => {
  const handleClick = (e: MouseEvent) => {
    let atLeastOne = true;
    refs.forEach((value) => {
      if (!(value?.current && !value.current.contains(e.target as any))) {
        atLeastOne = false;
      }
    });
    if (atLeastOne) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
