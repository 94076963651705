/**
 * Layout
 *  Component that houses the background and the foreground.
 */

import React from "react";

import { Footer } from "./Footer";

import "../styles/index.scss";
import "../styles/background.scss";

import { Navbar } from "./Navbar";

interface LayoutProps {
  hasFooter?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  hasFooter = true,
}) => {
  return (
    <>
      <Navbar />
      {children}
      {hasFooter && <Footer />}
    </>
  );
};
