import { Link } from "gatsby";
import React, { useState } from "react";
import { useOutsideClick } from "../hooks/useOutsideClick";

import "./styles/Navbar.scss";
import acssSeal from "../assets/img/seal-icon.png";
import yearBadge from "../assets/img/year-badge.svg";

export const Navbar: React.FC = () => {
  const [isNavExpanded, setNavExpanded] = useState(false);

  function toggleNavbar() {
    setNavExpanded(!isNavExpanded);
  }

  // Collapse the nav bar when the user clicked outside of it
  const navBarRef = React.useRef<HTMLUListElement>(null);
  useOutsideClick(navBarRef, () => setNavExpanded(false));

  return (
    <ul
      className={"Navbar" + (isNavExpanded ? " expanded" : "")}
      ref={navBarRef}
    >
      <div className="Navbar-items">
        <div id="mobile-nav-group">
          <Link id="official-seal" className="Navbar-item" to="/">
            <img src={acssSeal} alt="ACSS Official Seal" />
          </Link>
          <span className="nav-vertical-divider"></span>
          <button
            id="btn-hamburger-menu"
            // Switches menu button image depending on expanded state of navbar
            className={isNavExpanded ? "close" : ""}
            onClick={toggleNavbar}
          ></button>
        </div>
        <Link
          className="Navbar-item"
          activeClassName="Navbar-item active"
          to="/"
        >
          Home
        </Link>
        <Link
          className="Navbar-item"
          activeClassName="Navbar-item active"
          partiallyActive={true}
          to="/acss-week-2021"
        >
          ACSS Week
          <img id="year-badge" src={yearBadge} alt="2021 badge" />
        </Link>
        <Link
          className="Navbar-item"
          activeClassName="Navbar-item active"
          partiallyActive={true}
          to="/developers"
        >
          Developers
        </Link>
        <Link
          className="Navbar-item"
          activeClassName="Navbar-item active"
          partiallyActive={true}
          to="/events-and-activities"
        >
          Events &amp; Activities
        </Link>
        <Link
          className="Navbar-item"
          activeClassName="Navbar-item active"
          partiallyActive={true}
          to="/projects"
        >
          Projects
        </Link>
      </div>
    </ul>
  );
};
