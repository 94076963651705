/**
 * Layout
 *  Component that houses the footer of the page
 */

import React from "react";
import logo from "../assets/img/seal-bg-01.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import "../styles/index.scss";
import "../styles/footer.scss";
import "../styles/_utils.scss";

export const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer-area">
        <img src={logo} className="logo"></img>
        <div className="footer-division">
          <p className="org-name">
            <strong>Alliance of Computer Science Students UPLB</strong>
            <br />
            <span className="deemphasized">
              University of the Philippines
              <wbr /> Los Baños
            </span>
          </p>
          <address>
            <a href="mailto:hello@acssuplb.org">hello@acssuplb.org</a>
            <br />
          </address>
        </div>
        <div className="footer-division">
          <p className="org-name">
            <strong>Social Media</strong>
          </p>
          <div className="social-media-links">
            <a
              className="social-media-link"
              href="https://www.facebook.com/ACSSPH"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
            </a>
            <a
              className="social-media-link"
              href="https://twitter.com/ACSSUPLB"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon size="2x" icon={faTwitterSquare} />
            </a>
            <a
              className="social-media-link"
              href="https://www.linkedin.com/company/acssuplb/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon size="2x" icon={faLinkedin} />
            </a>
          </div>
        </div>
        <hr />
      </div>
      <i>2021 ACSS UPLB.</i>
    </footer>
  );
};
